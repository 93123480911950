<template>
  <div v-if="this.list" class="tabs" :class="$root.edit.can_edit_driver ? '': 'hide'">
    <div class="container" v-show="filter.flag">
      <Search :filterNone="'none'" @inputchange="search"/>
      <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
      <ul class="block-list">
        <li 
          :key="index"
          v-for="(item, index) in listNew"
          :data-id="item.id"   
          @click.prevent="openPopup(item, 'update')"  
        >
          <h4 style="margin-bottom: 0;padding: 5px 0">{{item.name}}</h4>
          <div class="icon icon-arrow-right"></div>
        </li>
      </ul>
      <div @click.prevent="openPopup('', 'create')" v-if="$root.add.can_edit_driver" class="add-plus"></div>
      <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
    </div>
    
    <div v-if="popupFlag">
      <Handbook :data="popup" @closePopup="closePopup" @close="popupFlag = false" />
    </div>
  </div>
</template>







<script>
import Search from "@/components/app/Search"
import Pagination from "@/components/app/Pagination"
import SelectedFilters from "@/components/app/SelectedFilters"
import Handbook from "@/components/app/Handbook"


export default {
  data: () => ({
    //title
    globalTitle: [
      {title: 'Причепи', info: ''}
    ],
    popupFlag: false,
    popup: {
      info: '',
      mod: 'created',
      contractor_id: '',
      modPopup: 'trailers'
    },


    params: {
      start: 0, 
      limit: 20,
    },
    filter: {
      flag: true,
      mod: 'city',
      screen: true
    },
    btnFilter: false,
    list: null,
    listNew: null,
    arrFilters: '',

    //
    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: [],

    quantityList: '',
    listNot: false
  }),




  async created() {

    //reload filters
    function getSearchParams(k){
      var p={};
      decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
      return k?p[k]:p;
    }
    var efe = getSearchParams()
    if(efe['f[start]']) {
      this.params.start = efe['f[start]']
    }
    if(efe['f[search]']) {
      this.searchData = 'f%5Bsearch%5D=' + efe['f[search]']
    }
    if(efe['search']) {
      this.searchData = 'search=' + efe['search']
    }
    this.filtersData = window.location.search
    this.arrFilters = window.location.search.slice(1)



    //title
    this.globalTitle[0].title = this.$root.langText.directory.trailers
    this.$root.title = this.globalTitle

    //get list
    const res = await this.$store.dispatch('postFiltersTrailers', {params: this.params, data: this.arrFilters})
    this.list = res.data;
    if(this.list){
      this.listNew = res.data.list;
      this.quantityList = res.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    }
  },
  methods: {
    
    
    openPopup(data, mod){
      this.popup.mod = mod
      this.popup.info = data
      this.popupFlag = true
    },
    async closePopup(){
      this.popupFlag = false
      var res = await this.$store.dispatch('postFiltersTrailers', {params: this.params, data: this.arrFilters})
      this.listNew = res.data.list
      this.quantityList = res.data.total_num
    },



    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + '&f%5Bstart%5D=' + this.params.start
      window.history.pushState('', '', window.location.origin + window.location.pathname + '?' + this.arrFilters)
      var arrFilters = await this.$store.dispatch('postFiltersTrailers', {params: this.params, data: this.arrFilters})
      this.listNew = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },

  
    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
      window.scrollTo(0,0);
    }
  },

  
  components: {
    Search, Pagination, SelectedFilters, Handbook
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.directory.trailers)
    }
  },
}

</script>